<template>
  <v-app>
    <v-main>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                  color="primary"
                  dark
                  flat
              >
                <v-toolbar-title>Dashboard</v-toolbar-title>
                <!--                <v-spacer></v-spacer>-->
                <!--                <v-tooltip bottom>-->
                <!--                  <template v-slot:activator="{ on }">-->
                <!--                    <v-btn-->
                <!--                        :href="source"-->
                <!--                        icon-->
                <!--                        large-->
                <!--                        target="_blank"-->
                <!--                        v-on="on"-->
                <!--                    >-->
                <!--                      <v-icon>mdi-code-tags</v-icon>-->
                <!--                    </v-btn>-->
                <!--                  </template>-->
                <!--                  <span>Source</span>-->
                <!--                </v-tooltip>-->
              </v-toolbar>
              <v-card-text>
                <!--                <script src="https://apis.google.com/js/platform.js" async defer></script>-->

                <v-row
                    justify="center"
                    align="center"
                >
                  <v-btn color="primary" @click="login()">
                    <v-icon class="mr-3">mdi-google</v-icon>
                    Login
                  </v-btn>
                  <!--                  <div class="g-signin2" data-onsuccess="onSignIn"></div>-->
                </v-row>


                <!--                <v-form>-->
                <!--                  <v-text-field-->
                <!--                      label="Login"-->
                <!--                      name="login"-->
                <!--                      prepend-icon="mdi-account"-->
                <!--                      type="text"-->
                <!--                  ></v-text-field>-->

                <!--                  <v-text-field-->
                <!--                      id="password"-->
                <!--                      label="Password"-->
                <!--                      name="password"-->
                <!--                      prepend-icon="mdi-lock"-->
                <!--                      type="password"-->
                <!--                  ></v-text-field>-->
                <!--                </v-form>-->
              </v-card-text>
              <!--              <v-card-actions>-->
              <!--                <v-spacer></v-spacer>-->
              <!--                <v-btn color="primary">Login</v-btn>-->
              <!--              </v-card-actions>-->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { AUTH_REQUEST } from '@/store/actions/auth'

  export default {
    name: 'Login',
    data: () => ({
      source: ''
    }),
    methods: {
      async login () {
        try {
          if (await this.$store.dispatch(AUTH_REQUEST, this)) {
            window.console.info('LOGIN OK',)
            await this.$router.push('/')
          } else {
            window.console.info('LOGIN ERROR',)
          }
        } catch (e) {
          window.console.info('LOGIN ERROR', e)
        }

        // let authCode
        // try {
        //   authCode = await this.$gAuth.getAuthCode()
        // } catch (e) {
        //   window.console.error(e)
        //   return
        // }
        // window.console.info('authCode', authCode)
        //
        // if (authCode) {
        //
        //   const response = await this.$http.get('http://localhost:3000/login/google/callback', {
        //     params: {
        //       code: authCode,
        //       state: '',
        //       origin: window.origin
        //     }
        //   })
        //   window.console.info('POST response', response)
        // }
      }
    }
  }
</script>

<style scoped>

</style>